@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  text-align: center;
}

#avatar {
   width: 300px;
}
.hero-img {
  max-width: 95vw;
}

@media screen and (min-width:700px) {
  .hero-img{
    max-width: 800px;
  }
}

h4, h5, h6 {
    font-family: eurostile, sans-serif;
}

p,h3 {
    font-family: europa, sans-serif;
    font-weight: 300;
    font-style: normal;
}

p{
    font-size: 1.5em;
}

h3 {
    font-size: 1.2em;
    line-height: 1.5;
}
h1, h2 {
font-family: eurostile, sans-serif;
font-weight: 700;
font-style: normal;
}

.main-title{
  color: #2d2d2d;
  text-align: center;
  text-transform: capitalize;
  padding: 0.7em 0;
}
.container .title{
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 10px;
}

.content {
  position: relative;
  width: 90%;
  max-width: 400px;
  margin: auto;
  overflow: hidden;
}

.content .content-overlay {
  background: rgb(6,10,144, 0.9);
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 15px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.content:hover .content-overlay{
  opacity: 1;
}

.content-image{
  width: 100%;
}

.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.content:hover .content-details{
  top: 50%;
  left: 50%;
  opacity: 1;
}

.content-details h3{
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.fadeIn-left{
    left: 20%;
  }

